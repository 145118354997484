<template src="./signed-in.html"></template>

<script>
export default {
  name: 'GondolaMiniSignedIn',
  computed: {
    user() {
      return this.$store.state.QRcode.user;
    },
  },
  created() {
    if (!this.$store.state.QRcode.isLoggedIn) {
      this.$store.dispatch('me').then((response) => {
        if (response) {
          setTimeout(() => {
            this.$router.push('/gondola-mini/recommendation');
          }, 6000);
        } else {
          this.$router.push('/gondola-mini/home');
        }
      });
    } else {
      setTimeout(() => {
        this.$router.push('/gondola-mini/recommendation');
      }, 6000);
    }
  },
};
</script>
